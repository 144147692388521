/* box style's */
.box-container {
  padding: 20px;
  margin: 10px;
  /* width: 300px; */
  height: auto ;
  text-align: left;
  position: relative;
  overflow: hidden;
  transition: all ease 0.3s;
  /* display:  flex; */
 background-color: #f5f7fd;
 border-radius: 5px;
 max-width: 560px;
 max-height: 250px;
}

.box-containe::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px; /* Adjust the height of the bottom border */
  width: 100%;
  background-color: #007bff; /* Change color to your desired border color */
  transform: scaleX(0); /* Initially scale to 0 width */
  transform-origin: bottom left;
  transition: transform 0.3s;
  
}

/* .box-container:hover {
  box-shadow: 2px 10px 10px 2px rgba(0, 0, 0, 0.1);
}

.box-container:hover::after {
  transform: scaleX(1); 
} */

.box-title {
  font-size: 1.5em;
  color: #333;
  
 margin-bottom: 20px;
}

.box-subtitle {
  font-size: 1.2em;
  color: #666;
}
.normal-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #018dea; /* Set your desired background color */
  color: #ffffff; /* Set your desired text color */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.normal-button:hover {
  background-color: #2079b0; /* Set your desired hover background color */
}

/* Outlined Button */
.outlined-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: 2px solid #3498db; /* Set your desired border color */
  color: #3498db; /* Set your desired text color */
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.outlined-button:hover {
  color: #2079b0; /* Set your desired hover text color */
  border-color: #2079b0; /* Set your desired hover border color */
}
/* this the custom button css */
.customtab__Button {
  margin-bottom: 10px;
  background-color: #fff;
  color: #242424;
  border-radius: 10px;
  box-shadow: 8px 10px 10px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: all ease 0.3s;
}
.customtab__ButtonActive{
  background-color: #ffff;
  border:none
}
.customtab__Button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px; /* Adjust the width of the left border */
  height: 100%; /* Set to full height */
  background-color: #007bff; /* Change color to your desired border color */
  transform: scaleY(0); /* Initially set to 0 height */
  transform-origin: top left;
  transition: transform 0.3s;
}

.customtab__Button.customtab__ButtonActive::after {
  transform: scaleY(1); /* Grow to full height on active state */
}

/* cutsom banner .csss */
.customSection2__jumbotron{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(./editedBackground.svg);
  background-repeat: initial;
 background-size: cover;
 margin-bottom: 100px;
}
.customSection2__h1{
  font-size: 58px;
  margin-bottom: 5px;
  color: rgb(0, 123, 252);
  
  line-height: 71px;
  font-weight: 400;
}
.customSection2__h2{
  font-size: 30px;
  margin-bottom: 5px;
  line-height: 70px;
  color: rgb(13, 30, 103);
  
  font-weight: 400;
}
.customSection2__p{
  color: rgb(94, 114, 144);
  font-size: 13px;
}
.customSection2__button{
  padding: 10px 20px ;
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #0a68f6;
  color: #ffff;
  transition: all ease 5ms;
 
}
.customSection2__button:hover{
  background-color:#0d1e67 ;
}
@media (max-width: 767px) {
  .customSection2__image {
    max-width: 100%; /* Adjust the max-width as needed for your mobile design */
    width: 50%; /* Ensures the image takes the full width of its container */
  }
  
  .box-container:hover::after {
    transform: scaleX(1); /* Expand the bottom border on hover */
  }
  
  .box-title {
    font-size: 1.5em;
    color: #333;
   margin-bottom: 20px;
  }
  
  .box-subtitle {
    font-size: 1em;
    color: #666;
  }
  .normal-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #018dea; /* Set your desired background color */
    color: #ffffff; /* Set your desired text color */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .normal-button:hover {
    background-color: #2079b0; /* Set your desired hover background color */
  }
  
  /* Outlined Button */
  .outlined-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: 2px solid #3498db; /* Set your desired border color */
    color: #3498db; /* Set your desired text color */
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease, border-color 0.3s ease;
  }
  
  .outlined-button:hover {
    color: #2079b0; /* Set your desired hover text color */
    border-color: #2079b0; /* Set your desired hover border color */
  }
  /* this the custom button css */
  .customtab__Button {
    margin-bottom: 10px;
    background-color: #fff;
    color: #242424;
    border-radius: 10px;
    box-shadow: 8px 10px 10px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    transition: all ease 0.3s;
  }
  .customtab__ButtonActive{
    background-color: #ffff;
    border:none
  }
  .customtab__Button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px; /* Adjust the width of the left border */
    height: 100%; /* Set to full height */
    background-color: #007bff; /* Change color to your desired border color */
    transform: scaleY(0); /* Initially set to 0 height */
    transform-origin: top left;
    transition: transform 0.3s;
  }
  
  .customtab__Button.customtab__ButtonActive::after {
    transform: scaleY(1); /* Grow to full height on active state */
  }
  
  /* cutsom banner .csss */
  .customSection2__jumbotron{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(./editedBackground.svg);
    background-repeat: initial;
   background-size: cover;
  }
  .customSection2__h1{
    font-size: 58px;
    margin-bottom: 5px;
    color: rgb(0, 123, 252);
    
    line-height: 71px;
    font-weight: 400;
  }
  .customSection2__h2{
    font-size: 30px;
    margin-bottom: 5px;
    line-height: 70px;
    color: rgb(13, 30, 103);
    
    font-weight: 400;
  }
  .customSection2__p{
    color: rgb(94, 114, 144);
    font-size: 13px;
  }
  .customSection2__button{
    padding: 10px 20px ;
    border-radius: 5px;
    border: 1px solid #efefef;
    background-color: #0a68f6;
    color: #ffff;
    transition: all ease 5ms;
  }
  .customSection2__button:hover{
    background-color:#0d1e67 ;
  }
  .customSection3__button{
    padding: 16px !important;
  background-color: #fff !important;
  color: #242424 !important;
  border-radius: 5px !important;
  transition: all ease-in 3s !important;
  }
  .customSection3__button:hover{
    background-color: #0d99ff !important;
    
  }
  @media (max-width: 767px) {
    .customSection2__image {
      max-width: 100%; /* Adjust the max-width as needed for your mobile design */
      width: 80%; /* Ensures the image takes the full width of its container */
      margin-top: 50px;
      /* margin-left: 30px; */
      align-items: center;
      /* margin-right: 20px; */
    }

  
@media (max-width: 767px){
.customSection3__image {
    max-width: 100%;
    width: 40%;
    margin-top: 20px; 
    margin-left: 13px;
    
}
  }

}









}

/* cutsome loader css */
/* Loader.css */

.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0062ff; /* Initial background color */
  color: white; /* Text color */
  animation: skewBackground 2s ease forwards; /* Animation duration and easing */
  animation-delay: 2s;
}

/* Animation for skewing background color */
@keyframes skewBackground {
  0% {
    transform: translateY(0); /* Start position above the container */
    opacity: 1; /* Fully visible */
  }
  100% {
    transform: translateY(-100%); /* End position at the top of the container */
    opacity: 0; /* Fully visible */
  }
}

.logo-container {
  margin-bottom: 20px; /* Adjust spacing */
}



.loading-text {
  position: absolute;
  bottom: 20px; /* Adjust position */
  left: 20px; /* Adjust position */
}

.copyrights {
  position: absolute;
  bottom: 20px; /* Adjust position */
  right: 20px; /* Adjust position */
}

/* Animation for background color filling from bottom to top */
@keyframes fillBackground {
  0% {
    height: 100%; /* Start with full height */
  }
  100% {
    height: 0; /* Fill to top */
  }
}
.loader-title{
  color: #fff;
}
.loader-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Fading white color */
  animation: fillBackground 3s ease forwards; /* Animation duration and easing */
} 
/* this is carousel card */


.carouselCard{
 
  border: 2px solid #efefef;
  color: #242424;
  border-radius: 10px;
  height: 550px;
  display: flex;
background-color: #ffffff;
/* background-image: url(../../images/carouselImages/cloudServices.png); */
 margin: 10px;
 padding: 25px;

 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 flex-direction: row;
}

@media (max-width: 767px) {
  .carouselCard {
      flex-direction: column; /* Stack elements vertically on mobile */
      height: 500px; /* Adjust height for mobile */
      padding: 10px; /* Reduce padding for mobile */
  }
  .Card__imgConatiner{
    width: 200px !important;
  }
  .CarouselImageWidth{
    width: 250px !important;
    height: 200px !important;
    /* object-fit: cover; Ensures image covers the area without distortion */
  }
  .single-services{
    height: auto;
  }
}
/* Material Design Input Styles */
.form-control-material {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control-material input,
.form-control-material select,
.form-control-material textarea {
  font-size: 16px;
  padding: 10px 5px;
  display: block;
  width: 100%;
  background: none;

  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.form-control-material input:focus,
.form-control-material select:focus,
.form-control-material textarea:focus {
  border: 1px solid #80bdff;
}

.form-control-material input:focus ~ label,
.form-control-material select:focus ~ label,
.form-control-material textarea:focus ~ label,
.form-control-material input:not(:placeholder-shown) ~ label,
.form-control-material select:not(:placeholder-shown) ~ label,
.form-control-material textarea:not(:placeholder-shown) ~ label {
  transform: translateY(-20px);
  font-size: 12px;
  color: #0d1e67;
}

.form-control-material label {
 
  color: #343a40;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.form-control-material input:not(:focus) ~ label,
.form-control-material select:not(:focus) ~ label,
.form-control-material textarea:not(:focus) ~ label {
  color: #343a40;
}

textarea {
  resize: none;
}

.form-check-label {
  font-size: 14px;
  color: #444;
}


.carouselButtonConatiner{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  
}
.ContactCont{
  background-color: #f8f8fb;
}
.contact-form-wrap{
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), /* Main shadow */
  0 1px 3px rgba(0, 0, 0, 0.08); /* Soft subtle shadow */

}
.Carousel__MainCont{

  width: 100%;
  height: auto;
}
.carouselCircleButton{

  width: 40px;
    height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #24242464;
  color: #fff;
  
}
.custom_fourboxes{
  width: 300px;
  background: #f3f5f7;
  padding: 20px;
  height: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 2px;
}

.custom_fourboxes:hover {
  -webkit-box-shadow: -2px 3px 14px -4px rgba(239, 239, 239, 1);
  -moz-box-shadow: -2px 3px 14px -4px rgba(239, 239, 239, 1);
  box-shadow: -2px 3px 14px -4px rgba(239, 239, 239, 1);
}
.hero-section{


height: 100vh ;
}

@media only screen and (max-width: 768px) {
  .carouselCard {
    padding-bottom: 25px;
  }
}

/* arrow button  */
.view-product-text{
  margin-right: 2px;
  transition: all ease 2s ;
}
.Product-feature  .view-product-text {
  display: none; /* Initially hide the text */
}

.Product-feature :hover .view-product-text {
  display: inline-block; /* Display the text when button is hovered */
}
/* Mobile view: max-width 768px (portrait/mobile devices) */
@media only screen and (max-width: 1034px) {
  .hero-section {
    height: auto; /* Adjust to auto height for small screens */
  }
}

/* Larger screens: min-width 1028px */
@media only screen and (min-width: 1028px) {
  .hero-section {
    height: auto; /* You can adjust this to the desired height */
  }
}

/*  */
.single-feature p {
  font-size: clamp(13px, 2vw, 16px);
}
.TwoColumns__section {
  margin-bottom: 20px;
  margin-top: 50px;
}

.TwoColumns__column-content {
  padding: 100px 10px 30px 30px;
  align-content: center;
  align-items: center;
}

.TwoColumns__column-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 10px;
  height: 270px;
  width: 270px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

}
/* this is conatct us page css */
.Conatact_container{
  width: 100%;
  /* height: 100vh; */

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
 
  position: relative;
  padding: 40px 40px 40px; /* Adjusted padding to accommodate the navbar */
  position: relative;
}
.formContainer {
  width: 600px;
  padding: 10px;
 
  border-radius: 5px;
  background-color: #ffff;
}

.inputBox {
  margin-bottom: 5px;
}

.inputBox label {
  display: block;
 
}
.inputBox input[type="text"]:focus,
.inputBox input[type="email"]:focus,
.inputBox textarea:focus,
.inputBoxselect:focus {

border: 1px solid #242323; /* Change this color to your desired focus color */
border-radius: 5px;
background-color: #fff;
color: #242424;
outline: none; /* Remove the default focus outline if desired */
}

.inputBox input,
.inputBox select,
.inputBox textarea {
  width: 100%;
  padding: 4px;
 
  border: 1px solid #242424;
  box-sizing: border-box;
  margin-top: 5px;
}

.inputBox input[type="submit"] {
  width: 20%;
  background-color: #232222;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.inputBox input[type="submit"]:hover {
  background-color: #1b0a09;
}
.radioContainer {
 
  /* padding: 20px; */
  margin: 20px 0;
}

.radioItem {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.radioItem input {
  margin-right: 10px;
}

.radioItem label {
  font-size: 16px;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.checkboxContainer input[type="checkbox"] {
  margin-right: 10px;
}


.halfWidth {
  width: 70%;
}
@media (max-width: 768px) {
  .container {
    padding: 60px 20px;
  }

  .formContainer {
    width: 100%;
  }

  .inputBox input[type="submit"] {
    width: 30%;
  }

  .halfWidth {
    width: 100%;
  }

  .inputBox input,
  .inputBox select,
  .inputBox textarea {
    width: 100%;
  }
}

/* custom css animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.Sliderbox{
  max-height: 500px;
  height: 500px;
  background-color: #ffffffd6;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  max-width: 500px;
gap: 20px;

  padding: 20px;
}
.SliderboxTextContainer{
color: #242424 !important;
display: flex;

flex-direction: column;
gap: 20px;
}
.SliderboxTextContainer p {
  font-size: 18px;
  line-height: 30px;
}
.Slider_hero-section {
 
padding: 40px;
}
.slideHeroSection{
  height: 70vh;
}
@media only screen and (max-width: 1024px) {
  .Slider_hero-section .slideHeroSection {
    height: auto; /* Set height to auto on tablets */
  }
}

/* Media query for mobile devices (portrait and landscape) */
@media only screen and (max-width: 768px) {
  .Slider_hero-section .slideHeroSection {
    height: auto; /* Set height to auto on mobile devices */
  }
}
.SliderHeader{
  line-height: 55px;
  font-weight: 400;
}

.SliderAniamtedCardContainer{
  display: flex;
 
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* animated Component */
.animated__Header{
  margin-bottom: 10px;
}
.animated-card{
  width: 300px;
  height: 300px;
  max-height: 400px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #242422;
  color: #efefef;
  border-radius: 10px;
  
}

.animated-card-title{
color: #ffff;
font-size: 24px !important;
}
.animated-card-container{
  max-height: 220px;
  height: 220px;
}

.animated-card-container  {
  position: relative;
}


/* icon box */
.iq-icon-box-style-1 {  margin-bottom: 30px; padding: 30px; border: 1px solid transparent; -webkit-border-radius: 6px; -moz-border-radius: 6px; border-radius: 6px; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.iq-icon-box-style-1 .icon-box-img { margin-right: 15px; }
.iq-icon-box-style-1 .icon-box-img img { width: 50px; margin-bottom: 10px; }
.iq-icon-box-style-1 .icon-box-img i { font-size: 45px; color: #007BFC; }
.iq-icon-box-style-1 .icon-box-content .icon-box-title { margin-bottom: 15px; }
.iq-icon-box-style-1 .icon-box-content .icon-box-title a { margin: 0 0 10px; }
.iq-icon-box-style-1 .icon-box-content .icon-box-desc { margin: 0px; }
.iq-icon-box-style-1:hover, .iq-icon-box-style-1.active {
   border-color: #f2f2f4; background: #ffffff; -webkit-box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06); box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06); transform: scale(1.1); /* Apply scale transformation */
   transition: transform 0.5s ease; /* Add transition for smooth scaling */}


   /* 
    */
    .BannerCloudServices{
      background-color:  hwb(41 25% 8%);
    }


  .carbtnCont{
    padding: 10px;
    margin: 10px;
    display: flex;
    gap: 5px;
  }.hover-dropdown .dropdown-toggle::after {
    display: none;
  }
  
  .hover-dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; /* Adjust as needed */
  }
  
/*  this is table component css */
.material-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.material-table thead {
  background-color: #fff;
  color: #fff;
}

.material-table thead th {
  padding: 12px;
  text-align: center;
  font-size: 16px;
}

.material-table tbody tr {
  transition: background-color 0.2s ease;
}

.material-table tbody tr:hover {
  background-color: #f5f5f5;
}

.material-table tbody td {
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}

/* Responsive list styles */
@media (max-width: 767.98px) {
  .ResponsiveTablerow {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 12px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .ResponsiveTablerow strong {
    display: block;
    font-weight: 600;
  }

  .ResponsiveTablerow > .col {
    padding: 8px 0;
  }
}
.carouselTitle {
  font-size: 60px; /* Default font size */
  font-weight: 500;
}

/* Media query for small screens */


/* Media query for small screens */
@media screen and (max-width: 576px) {
  .carouselTitle {
    font-size: 8.5vw; /* Adjust font size for smaller screens */
  }

  
}

/* Media query for medium screens */
@media screen and (min-width: 577px) and (max-width: 992px) {
  .carouselTitle {
    font-size: 7.5vw; /* Adjust font size for medium screens */
  }
}

/*  splash Screen Page  */
.SplashCard{
color: #242424;
box-shadow: 9px 3px 14px 5px rgba(255,255,NaN,1);
}
.SplashCardTitle{
  font-size: 30px;
  font-weight: 400;
}

.SplashCardSubTitle{
  font-size: 20px;
}

.SplashScreenNavbar{
  position: absolute;
  z-index: 999;
}

.SplashScreenNavbarImg{
  width: 200px;
  height: 50px;
}

.SplashScreenFooter{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

@media screen and (max-width: 576px) {
  
  .SplashScreenFooter{
   background-color: #1f70fa;
    bottom: auto;
    width: 100%;
    padding: 0;
  }
  
}




@media (max-width: 1199.98px) {
  .About__Row{
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
}

@media (max-width: 1360px) {
  .custom-lg-4 {
    flex: 0 0 33.3333% ; /* Equivalent to col-lg-4 */
    max-width: 33.3333% ;
  }
}

.lazy-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Blurred background */
.lazy-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(10px);
  opacity: 0.5;
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 1; /* Place behind the text */
  object-fit: cover;
  object-position: center; /* Ensure the image covers the container */
  background-size: cover !important;
}

.lazy-image.loaded {
  filter: blur(0);
  opacity: 1;
}

/* Text or content layer */
.lazy-image-content {
  position: relative;
  z-index: 2; /* Place above the background */
  text-align: center;
  color: #fff; /* Example: white text for visibility */
  padding: 20px;
}


/* placholder  */  
/* customcomponent.css */
.placeholder-wrapper {
  position: relative;
  display: inline-block;
  background-color: #f0f0f0; /* Light gray background for the placeholder */
  overflow: hidden;
}

.placeholder {
  background: linear-gradient(90deg, rgba(240, 240, 240, 1) 25%, rgba(220, 220, 220, 1) 50%, rgba(240, 240, 240, 1) 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes shimmer {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}

.hide {
  opacity: 0; /* Hide the placeholder smoothly */
  transition: opacity 0.5s ease-in-out; /* Smooth transition for hiding the placeholder */
}



/* Base cursor style */
.custom-cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 87, 51, 0.8); /* Custom color */
    border-radius: 50%;
    pointer-events: none; /* Prevents blocking interactions */
    transform: translate(-50%, -50%);
    transition: transform 0.15s ease-out, background-color 0.3s ease-in-out;
    mix-blend-mode: difference; /* Creates a cool blending effect */
    z-index: 9999;
}

/* Animation effect: Pulsing */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Apply animation to cursor */
.custom-cursor::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 87, 51, 0.2);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 1.2s infinite ease-in-out;
}

/* Scale up cursor on hover */
.box-container:hover ~ .custom-cursor {
    transform: scale(1.5);
    background-color: rgba(255, 87, 51, 1);
}


.mapContainer {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .mapContainer {
    height: auto;
  }
}

/* Marker Circle */
.marker-circle {
  fill: #624a96; /* Purple */
  stroke: white;
  stroke-width: 2;
  transition: transform 0.2s ease-in-out;
}

.marker-circle:hover {
  transform: scale(1.3);
}

/* Marker Labels */
.marker-label {
  font-size: 12px;
  font-weight: bold;
  fill: black; /* Text color */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease-in-out;
}

/* Show Label on Hover */
.marker-circle:hover + .marker-label {
  opacity: 1;
}
